import React, { useEffect, useRef } from 'react'
import { VStack, Widget, Text, Color, Box } from '@revolut/ui-kit'

import { OptionInterface } from '@src/interfaces/selectors'
import { TalentPerformanceInterface } from '@src/interfaces/talent/performance'
import { ReviewCategory, ReviewSummaryInterface } from '@src/interfaces/performance'
import Cards from '@src/pages/EmployeeProfile/Preview/Performance/Summary/Cards'
import NoReviewsWidget from '@src/pages/EmployeeProfile/Preview/Performance/Common/NoReviewsWidget'
import ReviewersFilter from '@src/pages/EmployeeProfile/Preview/Performance/Summary/ReviewersFilter'
import SidebarFeedbacks from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SidebarFeedbacks'
import { SummaryReviewTables } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/SummaryReviewTables'
import { BarRaiserSummary } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/BarRaiserSummary'
import { useHasNewScorecards } from '@src/utils/performance'

export interface ReviewsProps {
  data: ReviewSummaryInterface
  talent: TalentPerformanceInterface
  onFilterChanged: (option?: OptionInterface[]) => void
  scrollToBarRaiser?: boolean
}

export const ReviewsSection = ({
  data,
  talent,
  onFilterChanged,
  scrollToBarRaiser,
}: ReviewsProps) => {
  const { summary, reviews } = data
  const showNewScorecards = useHasNewScorecards()
  const barRaiserRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollToBarRaiser) {
      setTimeout(() => {
        barRaiserRef.current?.scrollIntoView({
          behavior: 'smooth',
        })
      }, 500)
    }
  }, [scrollToBarRaiser])

  return (
    <Widget p="s-16">
      <VStack space="s-16">
        <ReviewersFilter data={reviews} onFilterChange={onFilterChanged} showFilters />

        {showNewScorecards ? (
          <>
            {data.summary && (
              <VStack>
                <SummaryReviewTables
                  reviewData={data.summary}
                  reviewValues={data}
                  hideExpectations
                />
                <Box pt="s-24" ref={barRaiserRef}>
                  <BarRaiserSummary data={data.summary} hideBanner />
                </Box>
              </VStack>
            )}
          </>
        ) : (
          <Cards
            data={data}
            cycleId={String(talent.cycle.id)}
            employeeId={talent.employee.id}
            employeeSeniorityId={talent.seniority?.id}
            category={ReviewCategory.Performance}
          />
        )}
        {reviews.length ? (
          <div style={{ marginTop: '16px' }}>
            <Text mb={0} color={Color.GREY_TONE_50} variant="caption" fontWeight={500}>
              Feedback
            </Text>
            <SidebarFeedbacks
              renderTitle={false}
              feedbacks={summary?.overall_feedbacks}
              ratings={summary?.ratings}
            />
          </div>
        ) : (
          <NoReviewsWidget />
        )}
      </VStack>
    </Widget>
  )
}
