import React, { useEffect, useMemo, useState } from 'react'
import {
  Bar,
  Box,
  chain,
  Flex,
  Icon,
  IconButton,
  MoreBar,
  Spinner,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { connect } from 'lape'

import { useOrdering, useTable } from '@components/Table/hooks'
import {
  CellInsertParams,
  ColumnCellInterface,
  FilterByInterface,
  RowInterface,
  SORT_DIRECTION,
} from '@src/interfaces/data'
import {
  delegateCalibration,
  getTalentPerformanceItemsWithSeparators,
  talentPerformanceRequests,
  updateCalibratedGrade,
} from '@src/api/talent'
import {
  calibratorColumn,
  cultureRatingColumn,
  deliverablesRatingColumn,
  departmentColumn,
  employeeNameColumn,
  finalGradeColumn,
  finalRatingColumn,
  fmGradeColumn,
  fmGradePlainColumn,
  functionalManagerColumn,
  hodGradeColumn,
  hodRatingColumn,
  hofGradeColumn,
  hofRatingColumn,
  lineManagerColumn,
  lmGradeColumn,
  lmGradePlainColumn,
  positionColumn,
  rankingScoreColumn,
  seniorityColumn,
  skillsRatingColumn,
  systemRatingColumn,
  systemRecommendedGradeColumn,
  teamColumn,
} from '@src/constants/columns/talent/performance'
import {
  TalentPerformanceBulkGradeUpdateResponse,
  TalentPerformanceInterface,
  TalentPerformanceStatsInterface,
  TalentPerformanceStatsWeightsInterface,
} from '@src/interfaces/talent/performance'
import { getTalentTypes, TalentType } from '@src/interfaces/talent/talent'
import Loader from '@components/CommonSC/Loader'
import {
  ratingToColor,
  useHasNewScorecards,
  useSelectedPerformanceCycle,
} from '@src/utils/performance'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import AdjustableTable from '@components/Table/AdjustableTable'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { TeamInterface } from '@src/interfaces/teams'
import { RoleInterface, SpecialisationInterface } from '@src/interfaces/roles'
import {
  FinalGrade,
  getFinalGradeScore,
  PerformanceRating,
  ReviewCategory,
} from '@src/interfaces/performance'
import { PermissionTypes } from '@src/store/auth/types'
import { FunctionInterface } from '@src/interfaces/functions'
import { UpdateOrderingInterface } from '@src/interfaces/ordering'
import styled from 'styled-components'
import { RadioSelect } from '@components/Inputs/RadioSelect/RadioSelect'
import produce from 'immer'
import { PerformanceRatingTitle } from '@src/constants/performance'
import GradeLockIcon from '@src/features/Calibration/GradeLockIcon'
import SummarySidebar from './SummarySidebar/SummarySidebar'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { getInverseColorsAndGrade, GradesMapInterface } from '@src/utils/grades'
import { CompanyInterface } from '@src/interfaces/company'
import { EmployeeInterface } from '@src/interfaces/employees'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  calibratedBarRaiserColumn,
  calibratedDeliverablesRatingColumn,
  calibratedSkillsRatingColumn,
  calibrationNeededColumn,
  getCalibratedDeliverablesGradeColumn,
} from '@src/constants/columns/supportTool/calibratedGrade'
import {
  isActivePerformanceCycle,
  isDepartmentType,
  isFunctionType,
  useGetCycleSelector,
  useShowRanking,
} from '@src/pages/Forms/CommonTalentTab/utils'
import FilterDropdownButtons from '@components/FilterDropdownButtons/FilterDropdownButtons'
import PerformanceRatingLabelTag from '@components/PerformanceRatingLabelTag/PerformanceRatingLabelTag'
import TalentHeaderNIPS from '@src/pages/Forms/CommonTalentTab/TalentHeader/TalentHeaderNIPS'
import { formatNumber } from '@src/utils/format'
import MoreInfoButton from '@components/MoreInfoButton/MoreInfoButton'
import RequestFeedback, {
  RequestFeedbackSelector,
} from '@src/pages/EmployeeProfile/Preview/Performance/PerformanceReview/RequestFeedback'
import {
  NewFlowRequestsResponse,
  useFetcherPerformanceRequests,
} from '@src/pages/EmployeeProfile/Preview/Performance/Common/utils'
import { TableNames } from '@src/constants/table'
import { FilterSelectType } from '@components/Inputs/Filters/FilterSelect/NewFilterSelect'
import { OptionInterface } from '@src/interfaces/selectors'
import EmployeePerformanceChart from '@components/Charts/EmployeePerformanceChart/EmployeePerformanceChart'
import { getEmployeePerformanceGraph } from '@src/api/employees'
import { Colored } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import SelectTableWrapper, {
  SelectionControls,
  SelectTableWrapperOnChangeData,
} from '@src/components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import { getSelectCellConfig } from '@src/components/Table/AdvancedCells/SelectCell/SelectCell'
import { BulkGradeAssign } from './BulkGradeAssign'
import TalentCycleSettingsBanner from '@src/pages/Forms/CommonTalentTab/TalentCycleSettingsBanner'
import {
  DelegateCalibrationMoreAction,
  DelegateCalibrationProps,
} from './DelegateCalibration'
import { AllowedExportMenu } from '@src/features/ExportMenu/AllowedExportMenu'
import { TalentHelpBanner } from './TalentHelpBanner'
import { useQuery } from '@src/utils/queryParamsHooks'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { ShowGradeExplainAction } from './GradesExplanationSidebar/GradesExplanationSidebar'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { useIsNewLayout } from '@src/pages/EmployeeProfile/Layout/helpers'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { getCalibrationFilters } from '@src/features/Calibration/getCalibrationFilters'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { selectorKeys } from '@src/constants/api'
import round from 'lodash/round'
import isNumber from 'lodash/isNumber'
import {
  AboveLimitsIconWithTooltip,
  TalentHeaderGrades,
} from '@src/pages/Forms/CommonTalentTab/TalentHeader/TalentHeaderGrades'
import get from 'lodash/get'
import TalentHeaderStats from '@src/pages/Forms/CommonTalentTab/TalentHeader/TalentHeaderStats'
import { useTalentGroupFilters } from '@src/pages/Forms/CommonTalentTab/TalentGroupFilters'
import FilterButtonCheckboxSelect from '@components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { TableActionButton } from '@components/Button/TableActionButton'

const RadioSelectStyled = styled(RadioSelect)`
  min-height: 32px;
  border: 0;
  padding: 0;
`

interface TalentUIInterface extends TalentPerformanceInterface {
  isSelected?: boolean
  isPendingGrade?: boolean
}

interface TalentProps {
  data:
    | DepartmentInterface
    | TeamInterface
    | RoleInterface
    | SpecialisationInterface
    | FunctionInterface
    | CompanyInterface
    | EmployeeInterface
  talentType: TalentType
  navigation?: React.ReactElement
}

interface TalentContentProps extends TalentProps {
  initialCycleId: number | string
  cycles: OptionInterface[]
}

const ratingOptions = Object.values(PerformanceRating)
  .filter(id => id !== PerformanceRating.skipped && id !== PerformanceRating.dont_know)
  .map(id => ({
    id,
    name: PerformanceRatingTitle[id],
  }))

const getAboveBelowPrefilledGrades = (
  grade?: FinalGrade,
  prefilledGrade?: FinalGrade,
) => {
  if (!grade || !prefilledGrade) {
    return { abovePrefilled: false, belowPrefilled: false }
  }

  const gradeScore = getFinalGradeScore(grade)
  const prefilledScore = getFinalGradeScore(prefilledGrade)

  return {
    abovePrefilled: gradeScore > prefilledScore,
    belowPrefilled: gradeScore < prefilledScore,
  }
}

interface RowProps {
  talentType: TalentType
  changeHoDGrade: (id: number, rating: FinalGrade) => void
  changeHoFGrade: (id: number, rating: FinalGrade) => void
  changeHoFRating: (id: number, rating: PerformanceRating) => void
  canChangeHoFGrades?: boolean
  orderingMode?: boolean
  viewRatings?: boolean
  performanceSettings?: PerformanceSettingsInterface
  disableRowSelection: (row: TalentUIInterface) => boolean
  allowSelect: boolean
  viewHistory: boolean
  gradeOptions: { id: FinalGrade; name: string }[]
  showRanking: boolean
  showRankingColumn: boolean
  statsWeights: TalentPerformanceStatsWeightsInterface | null
  calibrationSelector: selectorKeys
  showNewScorecards?: boolean
  gradesMap: GradesMapInterface
  onBarRaiserClick: (talent: TalentUIInterface, scrollSidebar?: boolean) => void
}

const ROW = ({
  talentType,
  changeHoDGrade,
  changeHoFGrade,
  changeHoFRating,
  canChangeHoFGrades,
  orderingMode,
  viewRatings,
  performanceSettings,
  disableRowSelection,
  allowSelect,
  viewHistory,
  gradeOptions,
  showRanking,
  showRankingColumn,
  statsWeights,
  calibrationSelector,
  showNewScorecards,
  gradesMap,
  onBarRaiserClick,
}: RowProps): RowInterface<TalentUIInterface> => {
  let columns = viewRatings
    ? [
        {
          ...deliverablesRatingColumn,
          width: 122,
        },
        ...(performanceSettings?.enable_skill_assessment
          ? [
              {
                ...skillsRatingColumn,
                width: 122,
              },
            ]
          : []),
        ...(performanceSettings?.enable_values
          ? [
              {
                ...cultureRatingColumn,
                width: 122,
              },
            ]
          : []),
        {
          ...systemRatingColumn,
          width: 130,
        },
        {
          ...hodRatingColumn,
          width: 122,
        },
        {
          ...hofRatingColumn,
          width: 122,
          background: (data: TalentUIInterface) =>
            data.function_owner_rating_label && !data.self_employee_locked
              ? ratingToColor(data.function_owner_rating_label)
              : undefined,
          insert: ({ data }: CellInsertParams<TalentUIInterface>) => {
            if (data.self_employee_locked) {
              return <GradeLockIcon />
            }
            const value = ratingOptions.find(
              item => item.id === data.function_owner_rating_label,
            ) || {
              id: '',
              name: '',
              text: '',
            }

            const canChange =
              !orderingMode && canChangeHoFGrades && isFunctionType(talentType)

            if (!canChange) {
              return (
                <PerformanceRatingLabelTag
                  rating={data.function_owner_rating_label}
                  fontWeight={400}
                  fontSize="small"
                  emptyMessage="-"
                  data-testid={`label_rating_hof__${data.id}`}
                />
              )
            }

            return (
              <div onClick={e => e.stopPropagation()}>
                <RadioSelectStyled
                  id={`select_rating_hof__${data.id}`}
                  options={ratingOptions}
                  onChange={option => {
                    changeHoFRating(data.id, option.id as PerformanceRating)
                  }}
                  value={value}
                  width={480}
                />
              </div>
            )
          },
        },
        {
          ...finalRatingColumn,
          width: 122,
        },
      ]
    : [
        {
          ...calibratorColumn('department_grade_calibrator'),
          width: 50,
        },
        {
          ...calibratorColumn('function_grade_calibrator'),
          title: `${
            isDepartmentType(talentType) ? 'Functional' : 'Delegated'
          } calibrator`,
          width: 50,
        },
        {
          ...(viewHistory ? lmGradeColumn : lmGradePlainColumn),
          selectorsKey: calibrationSelector,
          width: 120,
        },
        {
          ...(viewHistory ? fmGradeColumn : fmGradePlainColumn),
          selectorsKey: calibrationSelector,
          width: 120,
        },
        ...(showNewScorecards
          ? [
              {
                ...getCalibratedDeliverablesGradeColumn(gradesMap),
                width: 120,
              },
            ]
          : [
              {
                ...calibratedDeliverablesRatingColumn,
                width: 120,
              },
            ]),
        {
          ...calibratedSkillsRatingColumn,
          width: 120,
        },
        {
          ...calibratedBarRaiserColumn,
          width: 120,
          insert: ({ data }: CellInsertParams<TalentUIInterface>) => {
            if (!data.line_manager_grade && !data.functional_manager_grade) {
              return null
            }
            return (
              <TableActionButton
                onClick={e => {
                  e.stopPropagation()
                  onBarRaiserClick(data, true)
                }}
              >
                View
              </TableActionButton>
            )
          },
        },
        {
          ...systemRecommendedGradeColumn,
          selectorsKey: calibrationSelector,
          width: 70,
        },
        {
          ...hodGradeColumn,
          width: 130,
          selectorsKey: calibrationSelector,
          insert: ({ data }: CellInsertParams<TalentUIInterface>) => {
            if (data.self_employee_locked) {
              return <GradeLockIcon />
            }

            const value = gradeOptions.find(
              item => item.id === data.department_owner_grade_suggestion?.id,
            ) || {
              id: '',
              name: '',
              text: '',
            }

            const readOnly = data.field_options.read_only.includes(
              'department_owner_grade_suggestion',
            )
            const canChange = !isDepartmentType(talentType) ? false : !readOnly

            const { color, grade } =
              getInverseColorsAndGrade(data.department_owner_grade_suggestion) || {}

            const extraGradeSuggestionValue = data.performance_extra_grade_suggestion?.id
            const extraGradeSuggestionTitle =
              data.performance_extra_grade_suggestion?.label

            if (!canChange) {
              return grade ? (
                <Text color={color} data-testid={`label_grade_hod__${data.id}`}>
                  {grade}
                </Text>
              ) : (
                <Text
                  color={Token.color.greyTone20}
                  style={{ fontStyle: 'italic' }}
                  data-testid={`label_grade_hod__${data.id}`}
                >
                  {extraGradeSuggestionTitle || '-'}
                </Text>
              )
            }

            return (
              <Flex alignItems="center" onClick={e => e.stopPropagation()}>
                <RadioSelectStyled
                  id={`select_grade_hod__${data.id}`}
                  options={gradeOptions}
                  onChange={option => {
                    changeHoDGrade(data.id, option.id as FinalGrade)
                  }}
                  value={{
                    ...value,
                    color,
                  }}
                  width={480}
                  placeholder={extraGradeSuggestionTitle}
                  italicPlaceholder={!!extraGradeSuggestionTitle}
                />
                {!value.id && extraGradeSuggestionValue && (
                  <IconButton
                    useIcon="Check"
                    color={Token.color.blue}
                    size={16}
                    ml="s-10"
                    onClick={() => {
                      changeHoDGrade(data.id, extraGradeSuggestionValue)
                    }}
                  />
                )}
              </Flex>
            )
          },
        },
        {
          ...hofGradeColumn,
          width: 140,
          selectorsKey: calibrationSelector,
          insert: ({ data }: CellInsertParams<TalentUIInterface>) => {
            if (data.self_employee_locked) {
              return <GradeLockIcon />
            }

            const value = gradeOptions.find(
              item => item.id === data.function_owner_grade_suggestion?.id,
            ) || {
              id: '',
              name: '',
              text: '',
            }

            const readOnly = data.field_options.read_only.includes(
              'function_owner_grade_suggestion',
            )
            const canChange = !isFunctionType(talentType) ? false : !readOnly

            const { color, grade } =
              getInverseColorsAndGrade(data.function_owner_grade_suggestion) || {}

            const extraGradeSuggestionValue = data.ranking_grade
            const extraGradeSuggestionTitle = data.ranking_grade
              ? gradesMap[data.ranking_grade]
              : undefined
            if (!canChange) {
              return grade ? (
                <Text color={color} data-testid={`label_grade_hof__${data.id}`}>
                  {grade}
                </Text>
              ) : (
                <Text
                  color={Token.color.greyTone20}
                  style={{ fontStyle: 'italic' }}
                  data-testid={`label_grade_hof__${data.id}`}
                >
                  {extraGradeSuggestionTitle || '-'}
                </Text>
              )
            }

            const selectedGrade = data.function_owner_grade_suggestion?.id
            const prefilledGrade = data.ranking_grade
            const prefilledGradeLabel = gradeOptions.find(
              option => option.id === prefilledGrade,
            )?.name

            const { abovePrefilled, belowPrefilled } = getAboveBelowPrefilledGrades(
              selectedGrade,
              prefilledGrade || undefined,
            )

            const adjustedOptions = gradeOptions.map(option => {
              if (!showRanking) {
                return option
              }

              const hodGrade = data.department_owner_grade_suggestion?.id
              const hodSelectedPoor = hodGrade === FinalGrade.Poor

              const summary =
                !prefilledGrade || option.id !== prefilledGrade
                  ? undefined
                  : '(pre-calibrated)'

              let disabled = !!hodGrade && option.id === FinalGrade.Poor
              if (hodSelectedPoor) {
                disabled = option.id !== FinalGrade.Poor
              }

              const tooltipText = disabled
                ? 'This grade cannot be selected, as the HoD input has priority for underperformance.'
                : undefined

              return { ...option, summary, disabled, tooltipText }
            })

            return (
              <Flex alignItems="center" onClick={e => e.stopPropagation()}>
                <RadioSelectStyled
                  id={`select_grade_hof__${data.id}`}
                  options={adjustedOptions}
                  onChange={option => {
                    changeHoFGrade(data.id, option.id as FinalGrade)
                  }}
                  value={{
                    ...value,
                    color,
                  }}
                  width={480}
                  placeholder={extraGradeSuggestionTitle}
                  italicPlaceholder={!!extraGradeSuggestionTitle}
                  icon={
                    showRanking &&
                    (abovePrefilled || belowPrefilled) && (
                      <Icon
                        name={abovePrefilled ? 'ArrowUp' : 'ArrowDown'}
                        color={abovePrefilled ? Token.color.green : Token.color.orange}
                        size={16}
                      />
                    )
                  }
                  tooltipText={
                    showRanking && (abovePrefilled || belowPrefilled)
                      ? `This grade has been calibrated ${
                          abovePrefilled ? 'up' : 'down'
                        } from ${prefilledGradeLabel}`
                      : undefined
                  }
                />
                {!value.id && extraGradeSuggestionValue && (
                  <IconButton
                    useIcon="Check"
                    size={16}
                    ml="s-10"
                    onClick={() => {
                      changeHoFGrade(data.id, extraGradeSuggestionValue)
                    }}
                  />
                )}
              </Flex>
            )
          },
        },
        ...(showRankingColumn
          ? [
              {
                ...rankingScoreColumn,
                width: 140,
                headerTooltip: statsWeights ? (
                  <VStack space="s-4" p="s-12" minWidth={260}>
                    <Text
                      variant="caption"
                      fontWeight={600}
                      color={Token.color.background}
                    >
                      How is this score calculated?
                    </Text>
                    <ul
                      style={{
                        margin: 0,
                        paddingInlineStart: 12,
                        color: Token.color.greyTone20,
                      }}
                    >
                      <li>
                        {statsWeights.average_manager_recommendation}% Manager grade
                        recommendation
                      </li>
                      <li>{statsWeights.deliverables}% Deliverables assessment</li>
                      <li>{statsWeights.skills}% Skills assessment</li>
                      <li>{statsWeights.kpi}% KPI performance</li>
                      <li>{statsWeights.wnips}% Bar raiser questions</li>
                      <li>
                        {statsWeights.past_cycles_average_grade}% Historic performance
                      </li>
                    </ul>
                  </VStack>
                ) : undefined,
                insert: ({ data }: CellInsertParams<TalentUIInterface>) => {
                  return isNumber(data.ranking_score) ? round(data.ranking_score, 2) : ''
                },
              },
            ]
          : []),
        {
          ...finalGradeColumn,
          width: 130,
          selectorsKey: calibrationSelector,
          insert: ({ data }: CellInsertParams<TalentUIInterface>) => {
            return (
              <Flex
                alignItems="center"
                justifyContent="space-between"
                data-testid={`final_grade__${data.id}`}
              >
                {data.self_employee_locked ? (
                  <GradeLockIcon />
                ) : (
                  <EmployeePerformanceChart
                    disabled={!viewHistory}
                    id={data.employee.id}
                    fetchKpi={getEmployeePerformanceGraph}
                    vertical="left"
                  >
                    <Flex justifyContent="space-between" width={96}>
                      {data.isPendingGrade ? (
                        <Spinner size={12} />
                      ) : (
                        <>
                          <Colored
                            style={{ fontSize: '13px' }}
                            color={
                              getInverseColorsAndGrade(data.display_grade)?.color || ''
                            }
                          >
                            {data.display_grade.label || '-'}
                          </Colored>

                          {viewHistory && (
                            <Icon
                              name="BarChart"
                              size={16}
                              color={Token.color.greyTone20}
                            />
                          )}
                        </>
                      )}
                    </Flex>
                  </EmployeePerformanceChart>
                )}
              </Flex>
            )
          },
        },
      ].filter(i => i)

  return {
    highlight: talent => (talent.isSelected ? Token.color.greyTone8 : ''),
    cells: [
      allowSelect
        ? {
            ...getSelectCellConfig({ disableRow: disableRowSelection }),
            width: 60,
          }
        : undefined,
      {
        ...employeeNameColumn,
        insert: ({ data }: CellInsertParams<TalentUIInterface>) => (
          <Flex gap="s-8">
            {canChangeHoFGrades && orderingMode && (
              <Icon name="Drag" color={Token.color.greyTone20} size={24} />
            )}
            <UserWithAvatar
              disableLink
              full_name={data.employee.full_name}
              avatar={data.employee.avatar}
              id={data.employee.id}
            />
          </Flex>
        ),
        width: 160,
      },
      {
        ...seniorityColumn,
        width: 70,
      },

      talentType !== TalentType.Specialisation
        ? {
            ...positionColumn,
            width: 100,
          }
        : undefined,
      isFunctionType(talentType)
        ? {
            ...departmentColumn,
            width: 120,
          }
        : undefined,
      talentType !== TalentType.Team
        ? {
            ...teamColumn,
            width: 120,
          }
        : undefined,
      talentType !== TalentType.DirectReport
        ? {
            ...lineManagerColumn,
            width: 50,
          }
        : undefined,
      talentType !== TalentType.FunctionalReport && {
        ...functionalManagerColumn,
        width: 50,
      },
      ...columns,
      talentType !== TalentType.Company
        ? {
            ...calibrationNeededColumn,
            width: 60,
          }
        : undefined,
    ].filter(i => i) as ColumnCellInterface<TalentUIInterface>[],
  }
}

const TalentPerformanceContent = ({
  data,
  talentType = TalentType.Function,
  cycles,
  initialCycleId,
  navigation,
}: TalentContentProps) => {
  const { entity } = useOrgEntity()
  const isComercial = useIsCommercial()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const [cycleEditable, setCycleEditable] = useState(
    isActivePerformanceCycle(initialCycleId, cycles),
  )
  const [openSidebar, setOpenSidebar] = useState(false)
  const [scrollToBarRaiser, setScrollToBarRaiser] = useState(false)
  const [openGradeExplain, setOpenGradeExplain] = useState(false)
  const [openReviewPopup, setOpenReviewPopup] = useState(false)
  const [selectedTalent, setSelectedTalent] =
    useState<{ index: number; talent: TalentUIInterface }>()
  const [showOrderingModeBtn, setShowOrderingModeBtn] = useState(false)
  const [tableDataWithoutSeparators, setTableDataWithoutSeparators] = useState<
    TalentUIInterface[]
  >([])
  const [viewRatings, setViewRatings] = useState(false)
  const [viewHistory, setViewHistory] = useState(false)
  const [selectedRows, setSelectedRows] = useState<TalentUIInterface[]>([])
  const [selectControls, setSelectControls] =
    useState<SelectionControls<TalentUIInterface>>()
  const [selectionState, setSelectionState] =
    useState<SelectTableWrapperOnChangeData<TalentUIInterface>>()
  const isNewLayout = useIsNewLayout()
  const showNewScorecards = useHasNewScorecards()

  const isEmployeeTalent =
    talentType === TalentType.FunctionalReport || talentType === TalentType.DirectReport
  const isOrganisationTalent = isDepartmentType(talentType)
  const isFunctionalTalent = isFunctionType(talentType)

  const showRanking = useShowRanking(talentType)

  const disableRowSelection = (row: TalentUIInterface) => {
    if (enabledBulkDelegate) {
      return false
    }
    if (isDepartmentType(talentType)) {
      return row.field_options.read_only.includes(
        PermissionTypes.DepartmentOwnerGradeSuggestion,
      )
    }
    if (showRanking) {
      const hodGrade = row.department_owner_grade_suggestion?.id
      return (
        hodGrade === FinalGrade.Poor ||
        row.field_options.read_only.includes(PermissionTypes.FunctionOwnerGradeSuggestion)
      )
    }
    return row.field_options.read_only.includes(
      PermissionTypes.FunctionOwnerGradeSuggestion,
    )
  }

  const handleSelect = (
    tableWrapperState: SelectTableWrapperOnChangeData<TalentUIInterface>,
  ) => {
    setSelectionState(tableWrapperState)
    if (tableWrapperState && tableWrapperState.selectedRowsData.length) {
      setSelectedRows(tableWrapperState.selectedRowsData)
    } else if (tableWrapperState.isAllSelected) {
      const selected = table.data.filter(
        row =>
          !disableRowSelection(row) &&
          !tableWrapperState.excludeListIds.has(String(row.id)),
      )
      setSelectedRows(selected)
    } else {
      setSelectedRows([])
    }
  }

  const feedbackRequestsContext = useFetcherPerformanceRequests({
    category: ReviewCategory.Performance,
    isNewFlow: true,
    id: selectedTalent ? selectedTalent.talent.employee.id : undefined,
    performanceCycle:
      openReviewPopup && selectedTalent
        ? {
            id: selectedTalent.talent.cycle?.id,
            category: ReviewCycleCategory.Performance,
          }
        : undefined,
  })
  const cycleSelector = useGetCycleSelector(cycles)

  const TalentTypes = getTalentTypes(data.id, true)

  const canChangeHoDGrades =
    cycleEditable &&
    isOrganisationTalent &&
    data.field_options.permissions?.includes(PermissionTypes.ChangeGradeSuggestion)

  const canChangeHoFGrades =
    cycleEditable &&
    isFunctionalTalent &&
    data.field_options.permissions?.includes(PermissionTypes.ChangeTalentRank)

  const [enabledBulkAssign, setEnabledBulkAssign] = useState(false)
  const [enabledBulkDelegate, setEnabledBulkDelegate] = useState(false)
  const [showRankingColumn, setShowRankingColumn] = useState(false)
  const [orderingMode, setOrderingMode] = useState<boolean>(
    showRanking && !!canChangeHoFGrades,
  )

  const initialSortBy = [
    ...(showRanking
      ? [
          {
            sortBy: 'function_owner_grade_suggestion__value',
            direction: SORT_DIRECTION.ASC,
            nonResettable: true,
          },
          {
            sortBy: 'ranking_score',
            direction: SORT_DIRECTION.ASC,
            nonResettable: true,
          },
        ]
      : [
          {
            sortBy: 'absolute_rating_score__value',
            direction: SORT_DIRECTION.ASC,
            nonResettable: true,
          },
        ]),
  ]

  const { filter, nipsGraphRequest } = TalentTypes[talentType]

  // TODO: this is temporary, get rid of it once BE implements consistent filter
  if (filter && talentType === TalentType.FunctionalReport) {
    filter.columnName = 'functional_manager__id'
  }

  const categoryFilter = showRanking
    ? {
        filters: [{ name: `${talentType}`, id: talentType }],
        columnName: 'category',
        nonResettable: true,
      }
    : undefined

  const initialFilter = [
    filter,
    categoryFilter,
    {
      filters: [{ name: `${initialCycleId}`, id: initialCycleId }],
      columnName: 'cycle__id',
      nonResettable: true,
    },
    ...getCalibrationFilters(entity),
  ].filter(i => i) as FilterByInterface[]

  const table = useTable<TalentUIInterface, TalentPerformanceStatsInterface>(
    {
      ...talentPerformanceRequests,
      getItems: showRanking
        ? getTalentPerformanceItemsWithSeparators
        : talentPerformanceRequests.getItems,
    },
    initialFilter,
    initialSortBy,
  )

  useEffect(() => {
    setTableDataWithoutSeparators(
      table.data.filter(row => (isNumber(row.id) ? row.id > 0 : true)),
    )
  }, [table.data])

  const [queuedSidebarTalent, setQueuedSidebarTalent] = useState<number | null>(null)

  const employeesOptions = useFetchOptions<OptionInterface>(
    selectorKeys.all_employees,
  ).options.map(({ value }) => value)
  const seniorityOptions = useFetchOptions<OptionInterface>(
    selectorKeys.seniority,
  ).options.map(({ value }) => value)

  const [selectedEmployeesFilter, setSelectedEmployeesFilter] = useState<
    OptionInterface[]
  >(table.filterBy.find(item => item.columnName === 'seniority__id')?.filters || [])
  const [selectedSeniorityFilter, setSelectedSeniorityFilter] = useState<
    OptionInterface[]
  >(table.filterBy.find(item => item.columnName === 'seniority__id')?.filters || [])

  const departmentsOptions = useFetchOptions<OptionInterface>(
    selectorKeys.department,
  ).options.map(({ value }) => value)
  const [selectedDepartmentFilter, setSelectedDepartmentFilter] = useState<
    OptionInterface[]
  >(table.filterBy.find(item => item.columnName === 'department__id')?.filters || [])

  useEffect(() => {
    if (queuedSidebarTalent && tableDataWithoutSeparators[queuedSidebarTalent]) {
      setSelectedTalent({
        talent: tableDataWithoutSeparators[queuedSidebarTalent],
        index: queuedSidebarTalent,
      })
    }
  }, [tableDataWithoutSeparators])

  const getCanBulkCalibrate = (
    type: TalentType,
    hasHodPermissions: boolean,
    hasHofPermissions: boolean,
  ) => {
    const isBulkEligibleType = [
      TalentType.Department,
      TalentType.Function,
      TalentType.Specialisation,
    ].includes(type)

    const hasBulkCalibrationPermissions = isDepartmentType(type)
      ? hasHodPermissions
      : hasHofPermissions

    return isBulkEligibleType && hasBulkCalibrationPermissions
  }

  const allowBulkCalibration =
    getCanBulkCalibrate(talentType, !!canChangeHoDGrades, !!canChangeHoFGrades) &&
    (!showRanking || enabledBulkAssign)

  const allowDelegation = data.field_options.permissions?.includes(
    PermissionTypes.CanDelegateCalibrators,
  )
  const showBulkAssign = showRanking && canChangeHoFGrades

  const onAfterChange = async (
    requestData: UpdateOrderingInterface,
    originalData: TalentUIInterface[],
    updatedData: TalentUIInterface[],
  ) => {
    const id = requestData.item_object_ids[0]
    const afterRowIdx = originalData.findIndex(
      item => item.id === requestData.target_position_object_id,
    )
    let beforeRowItem = originalData[afterRowIdx - 1]

    if (afterRowIdx === -1) {
      beforeRowItem = originalData[originalData.length - 1]
    }

    const requestedGrade = beforeRowItem.function_owner_grade_suggestion?.id
    const currentGrade = updatedData.find(item => item.id === id)
      ?.function_owner_grade_suggestion?.id

    if (id && requestedGrade && requestedGrade !== currentGrade) {
      await changeHoFGrade(Number(id), requestedGrade)
    }
  }

  const ignoreChangeOrder = (
    sourceIds: (number | string)[],
    activeIndex: number | null,
    targetIndex: number,
  ) => {
    // if moving separator string
    if (isNumber(sourceIds[0]) && sourceIds[0] < 0) {
      return true
    }

    if (!activeIndex) {
      return false
    }

    const movedItem = table.data[activeIndex]
    const currentGrade = movedItem.function_owner_grade_suggestion?.id
    const selectedItemIndex = targetIndex > activeIndex ? targetIndex : targetIndex - 1
    const selectedGrade =
      table.data[selectedItemIndex].function_owner_grade_suggestion?.id
    const changedGrade = currentGrade !== selectedGrade

    const hodGrade = movedItem.department_owner_grade_suggestion?.id
    const hodSelectedPoor = hodGrade === FinalGrade.Poor
    const movingOutsidePoor = hodSelectedPoor && selectedGrade !== FinalGrade.Poor
    const movingToPoor =
      !!hodGrade && hodGrade !== FinalGrade.Poor && selectedGrade === FinalGrade.Poor

    // !changedGrade - if user moved line within the same grade group, this means that grade didn't change, so we should ignore further calculation
    // movingOutsidePoor - if HoD calibrated with Unsatisfactory, HoF shouldn't be able to calibrate with anything except Unsatisfactory
    // movingToPoor - if HoD calibrated with grade other than Unsatisfactory, HoF shouldn't be able to calibrate with Unsatisfactory
    return !changedGrade || movingOutsidePoor || movingToPoor
  }

  const { selectedOrderingIds, onChangeOrder } = useOrdering(
    table.data,
    table.setData,
    table.count,
    table.refresh,
    onAfterChange,
    ignoreChangeOrder,
  )

  const updateAfterChangingGrade = (id: number, responseData: TalentUIInterface) => {
    if (responseData) {
      table.updateRows(
        row => row.id === id,
        () => ({ ...responseData, isPendingGrade: false }),
      )

      if (selectedTalent && selectedTalent.talent.id === id) {
        setSelectedTalent({
          talent: responseData,
          index: selectedTalent.index,
        })
      }
    }
  }

  const changeHoFRating = async (id: number, rating: PerformanceRating) => {
    table.updateRows(
      row => row.id === id,
      row => ({ ...row, function_owner_rating_label: rating }),
    )

    try {
      const response = await updateCalibratedGrade(
        id,
        {
          function_owner_rating_label: rating,
        },
        getCalibrationFilters(entity),
      )
      updateAfterChangingGrade(id, response.data)
    } catch (e) {
      table.refresh()
    }
  }

  const getChangeGradeHandler =
    (field: 'department_owner_grade_suggestion' | 'function_owner_grade_suggestion') =>
    async (id: number, grade: FinalGrade) => {
      table.updateRows(
        row => row.id === id,
        row => ({ ...row, [field]: grade, isPendingGrade: true }),
      )

      try {
        const response = await updateCalibratedGrade(
          id,
          {
            [field]: grade,
          },
          getCalibrationFilters(entity),
        )
        updateAfterChangingGrade(id, response.data)
        table.refreshStats()
      } catch (e) {
        table.refresh()
      } finally {
        table.updateRows(
          row => row.id === id,
          row => ({ ...row, isPendingGrade: false }),
        )
      }
    }

  const changeHoDGrade = getChangeGradeHandler('department_owner_grade_suggestion')

  const changeHoFGrade = getChangeGradeHandler('function_owner_grade_suggestion')

  const filterQuery = filterSortPageIntoQuery(table.sortBy, table.filterBy, 1)

  const NIPS = table.stats?.nips ? +formatNumber(table.stats?.nips * 100, 1) : 0

  const onBulkGradeAssigned = (results: TalentPerformanceBulkGradeUpdateResponse[]) => {
    if (showRanking) {
      table.refresh()
    } else {
      const updatedData = produce(table.data, draft => {
        results.forEach(updated => {
          const tableRow = draft.find(row => row.id === updated.id)
          if (tableRow) {
            tableRow.department_owner_grade_suggestion =
              updated.department_owner_grade_suggestion
            tableRow.function_owner_grade_suggestion =
              updated.function_owner_grade_suggestion
            tableRow.display_grade = updated.display_grade
          }
        })
      })

      const rowsToKeepSelected =
        results.length === selectedRows.length
          ? []
          : selectedRows.filter(
              selRow => !results.find(resRow => resRow.id === selRow.id),
            )

      selectControls && selectControls.setSelection(rowsToKeepSelected)
      table.setData(updatedData)
    }
  }

  const onDelegate: DelegateCalibrationProps['delegate'] = async ({
    calibratorId,
    talents,
  }) => {
    const calibratorKey = isDepartmentType(talentType)
      ? 'department_grade_calibrator'
      : 'function_grade_calibrator'

    const {
      data: { results },
    } = await delegateCalibration(
      {
        grades: talents && talents.length ? talents.map(talent => talent.id) : null,
        include_all: !!selectionState?.isAllSelected,
        exclude_ids: selectionState?.isAllSelected
          ? Array.from(selectionState?.excludeListIds).map(Number)
          : null,
        [calibratorKey]: { id: calibratorId },
      },
      [...table.filterBy, ...getCalibrationFilters(entity)],
    )

    const updatedData = produce(table.data, draft => {
      results.forEach(updated => {
        const tableRow = draft.find(row => row.id === updated.id)
        if (tableRow) {
          tableRow.department_grade_calibrator = updated.department_grade_calibrator
          tableRow.function_grade_calibrator = updated.function_grade_calibrator
        }
      })
    })
    table.setData(updatedData)
    selectControls?.setSelection([])
  }

  const handleCycleChange = (selectedCycle: OptionInterface) => {
    setScrollToBarRaiser(false)
    setOpenSidebar(false)
    setSelectedTalent(undefined)
    setCycleEditable(isActivePerformanceCycle(selectedCycle.id, cycles))
  }

  const onRowClick = (talent: TalentUIInterface, scrollSidebar: boolean = false) => {
    table.setData(
      produce(table.data, draft => {
        const found = draft.find(item => item.id === talent.id)
        if (found) {
          found.isSelected = true
        }
      }),
    )
    const index = tableDataWithoutSeparators.findIndex(
      rowData => rowData.id === talent.id,
    )
    setSelectedTalent({ talent, index })
    setScrollToBarRaiser(scrollSidebar)
    setOpenGradeExplain(false)
    setOpenSidebar(true)
  }

  const getMoreBarCount = () => {
    // we want to hide 3 last buttons behind show more (...) dropdown
    // but ui-kit allows to set maxCount only, so we have to keep track of overal count
    // there are 2 buttons that's always shown - View ratings and View history
    // for internal customer only there is also ShowGradeExplainAction
    // the rest buttons depend on permissions
    const alwaysShownBtns = isComercial ? 2 : 3
    const btnsCount =
      alwaysShownBtns +
      Number(allowBulkCalibration) +
      Number(allowDelegation) +
      Number(canChangeHoFGrades && showOrderingModeBtn)

    return btnsCount
  }

  const filteredFilters = useMemo(() => {
    return table.filterBy
      ?.find((filterBy: FilterByInterface) => filterBy.columnName === 'cycle__id')
      ?.filters.filter(option => option.id !== '' && option.name !== '')
  }, [table.filterBy])

  const selectedCycle = useMemo(() => {
    return cycles.find(cycle => cycle.cycle_id === Number(filteredFilters?.[0].id))
  }, [filteredFilters, cycles])

  const calibrationSelector =
    selectedCycle?.grade_labels_version === 1
      ? selectorKeys.performance_grades
      : selectorKeys.calibration_grades

  const groupFilters = useTalentGroupFilters(table)
  const { options: calibrationGradesOptions } = useFetchOptions<{
    id: FinalGrade
    name: string
  }>(calibrationSelector)
  const gradeOptions = calibrationGradesOptions.map(el => {
    if (el.value.id === '_empty') {
      return { id: FinalGrade.None, name: '' }
    }
    return el.value
  })

  const gradesMap: GradesMapInterface = {}
  calibrationGradesOptions.forEach(option => {
    gradesMap[option.value.id] = option.value.name
  })

  const row = useMemo(() => {
    return ROW({
      viewHistory,
      talentType,
      changeHoDGrade,
      changeHoFGrade,
      changeHoFRating,
      canChangeHoFGrades,
      orderingMode,
      viewRatings,
      performanceSettings,
      disableRowSelection,
      allowSelect: allowBulkCalibration || enabledBulkDelegate,
      gradeOptions,
      showRankingColumn,
      showRanking,
      statsWeights: table.stats?.weights || null,
      calibrationSelector,
      showNewScorecards,
      gradesMap,
      onBarRaiserClick: onRowClick,
    })
  }, [
    performanceSettings,
    viewHistory,
    talentType,
    canChangeHoFGrades,
    orderingMode,
    viewRatings,
    allowBulkCalibration,
    enabledBulkDelegate,
    gradeOptions,
    table.stats,
    showNewScorecards,
    gradesMap,
  ])

  const renderSeparatorContent = !showRanking
    ? undefined
    : (rowData: TalentUIInterface, index: number) => {
        const prevRowData = table.data[index - 1]
        if (
          isOrganisationTalent &&
          rowData.department_owner_grade_suggestion?.id ===
            prevRowData?.department_owner_grade_suggestion?.id
        ) {
          return null
        }
        if (
          isFunctionalTalent &&
          rowData.function_owner_grade_suggestion?.id ===
            prevRowData?.function_owner_grade_suggestion?.id
        ) {
          return null
        }

        const gradeFieldName = isFunctionalTalent
          ? 'function_owner_grade_suggestion'
          : 'department_owner_grade_suggestion'

        const statsGradeId = get(rowData, gradeFieldName)?.id || ''
        const count = get(table.stats, statsGradeId) || table.stats?.unassigned
        const gradeId = isFunctionalTalent
          ? rowData.function_owner_grade_suggestion?.id
          : rowData.department_owner_grade_suggestion?.id
        const aboveLimit = !!get(table.stats?.above_limits, statsGradeId)

        return (
          <Flex
            pl="s-16"
            alignItems="center"
            color={Token.color.greyTone50}
            borderLeft={`2px solid ${Token.color.grey50}`}
            style={{ fontWeight: 500 }}
            gap="s-8"
          >
            {chain(gradeId ? gradesMap[gradeId] : 'Unassigned', count)}
            {aboveLimit && <AboveLimitsIconWithTooltip size={15} />}
          </Flex>
        )
      }

  useEffect(() => {
    table.data.forEach(t => {
      const selectedTalentId = selectedTalent?.talent.id
      t.isSelected = selectedTalentId === t.id
    })
  }, [selectedTalent, table.data])
  return (
    <VStack gap="s-32">
      {!(isNewLayout && isEmployeeTalent) && !isOrganisationTalent && (
        <TalentHelpBanner type={talentType} />
      )}

      <Widget display="flex" p="s-16" width="100%">
        <Flex flexDirection="column" width="100%">
          {((isNewLayout && isEmployeeTalent) || isOrganisationTalent) && (
            <>
              {navigation}
              <TalentHelpBanner type={talentType} mb="s-24" />
            </>
          )}
          {showRanking ? (
            <Flex mb="s-24" mt="s-8">
              <Box mr="s-32">
                <CycleFilter
                  columnName="cycle__id"
                  filter={table.filterBy}
                  filterInputType={FilterSelectType.SingleSelect}
                  onFilterChange={table.onFilterChange}
                  onChange={handleCycleChange}
                  selector={cycleSelector}
                  type={CycleFilterType.NewUI}
                />
              </Box>
              {nipsGraphRequest && (
                <TalentHeaderNIPS
                  id={data.id}
                  nips={NIPS}
                  nipsGraphRequest={nipsGraphRequest}
                  label="Final grade NIPS"
                />
              )}
              <TalentHeaderGrades gradesMap={gradesMap} stats={table.stats} />
            </Flex>
          ) : (
            <Flex mb="s-24" justifyContent="space-between">
              {groupFilters}
              <Flex flex={1} pt="s-8" justifyContent="flex-end">
                {nipsGraphRequest && (
                  <TalentHeaderNIPS
                    id={data.id}
                    nips={NIPS}
                    nipsGraphRequest={nipsGraphRequest}
                  />
                )}
                <TalentHeaderStats
                  lmCompleted={table.stats?.line_manager_completed_review_ratio}
                  fmCompleted={table.stats?.functional_manager_completed_review_ratio}
                />
                <CycleFilter
                  columnName="cycle__id"
                  filter={table.filterBy}
                  filterInputType={FilterSelectType.SingleSelect}
                  onFilterChange={table.onFilterChange}
                  onChange={handleCycleChange}
                  selector={cycleSelector}
                  type={CycleFilterType.NewUI}
                />
              </Flex>
            </Flex>
          )}

          <Flex alignItems="center" justifyContent="space-between" mb="s-16">
            <MoreBar maxCount={getMoreBarCount()}>
              {showBulkAssign && (
                <SwitchButton
                  checked={enabledBulkAssign}
                  onClick={() => {
                    setOrderingMode(!orderingMode)
                    setEnabledBulkAssign(!enabledBulkAssign)
                  }}
                >
                  Bulk assign
                </SwitchButton>
              )}
              {allowDelegation && !showBulkAssign && (
                <SwitchButton
                  checked={enabledBulkDelegate}
                  onClick={() => {
                    setOrderingMode(!orderingMode)
                    setEnabledBulkDelegate(!enabledBulkDelegate)
                  }}
                >
                  Bulk delegate
                </SwitchButton>
              )}
              {allowBulkCalibration && (
                <BulkGradeAssign
                  talents={selectedRows}
                  talentType={talentType}
                  onSuccess={onBulkGradeAssigned}
                  selector={calibrationSelector}
                />
              )}
              {allowDelegation && (allowBulkCalibration || enabledBulkDelegate) && (
                <DelegateCalibrationMoreAction
                  disabled={!selectionState?.isAllSelected && !selectedRows.length}
                  talents={selectionState?.isAllSelected ? undefined : selectedRows}
                  delegate={onDelegate}
                />
              )}
              <HideIfCommercial>
                <ShowGradeExplainAction
                  open={openGradeExplain}
                  onOpen={() => {
                    setOpenGradeExplain(true)
                    setSelectedTalent(undefined)
                    setScrollToBarRaiser(false)
                    setOpenSidebar(false)
                  }}
                  onClose={() => setOpenGradeExplain(false)}
                />
              </HideIfCommercial>
              {showRanking && (
                <SwitchButton
                  checked={showRankingColumn}
                  onClick={() => {
                    setShowRankingColumn(!showRankingColumn)
                  }}
                >
                  Show ranking
                </SwitchButton>
              )}
              {!showRanking && (
                <>
                  <SwitchButton
                    checked={viewRatings}
                    onClick={() => {
                      setShowOrderingModeBtn(!viewRatings)
                      if (orderingMode) {
                        setOrderingMode(false)
                      }
                      setViewRatings(!viewRatings)
                    }}
                  >
                    View ratings
                  </SwitchButton>
                  <SwitchButton
                    tooltipContent={
                      viewHistory
                        ? 'Hide grade history charts'
                        : 'View grade history charts'
                    }
                    checked={viewHistory}
                    onClick={() => {
                      setViewHistory(!viewHistory)
                    }}
                  >
                    View history
                  </SwitchButton>
                </>
              )}
              <AllowedExportMenu
                request={talentPerformanceRequests.getExport}
                filterQuery={filterQuery}
                fileName="Talent performance"
                type={talentType}
              />
              <MoreInfoButton href="https://revolut.atlassian.net/wiki/spaces/REV/pages/2471641003/Performance+Review+-+Head+of+Department+Function+Inputs" />
            </MoreBar>
            <Bar>
              {showRanking && (
                <>
                  <FilterButtonCheckboxSelect
                    label="Delegated calibrator"
                    searchable
                    width={300}
                    options={employeesOptions || []}
                    value={selectedEmployeesFilter}
                    onChange={selection => {
                      setSelectedEmployeesFilter(selection || [])
                      const columnName = isFunctionalTalent
                        ? 'function_grade_calibrator__id'
                        : 'department_grade_calibrator__id'
                      table.onFilterChange({
                        filters: selection || [],
                        columnName,
                      })
                    }}
                  />
                  <FilterButtonCheckboxSelect
                    label="Department"
                    searchable
                    width={300}
                    options={departmentsOptions || []}
                    value={selectedDepartmentFilter}
                    onChange={selection => {
                      setSelectedDepartmentFilter(selection || [])
                      table.onFilterChange({
                        filters: selection || [],
                        columnName: 'department__id',
                      })
                    }}
                  />
                </>
              )}
              <FilterButtonCheckboxSelect
                label="Seniority"
                searchable
                width={300}
                options={seniorityOptions || []}
                value={selectedSeniorityFilter}
                onChange={selection => {
                  setSelectedSeniorityFilter(selection || [])
                  table.onFilterChange({
                    filters: selection || [],
                    columnName: 'seniority__id',
                  })
                }}
              />
              <FilterDropdownButtons
                items={[
                  {
                    id: 'lm_fm_mismatch',
                    name: 'LM-FM mismatch',
                    count: table.stats?.lm_fm_mismatch_count,
                  },
                  {
                    id: 'department_owner_calibrated',
                    name: 'HoD calibrated',
                    count: table.stats?.department_owner_calibrated_count,
                  },
                  {
                    id: 'function_owner_calibrated',
                    name: 'HoF calibrated',
                    count: table.stats?.function_owner_calibrated_count,
                  },
                  {
                    id: 'underperformers_only',
                    name: 'Underperformers',
                    count: table.stats?.underperformers_count,
                  },
                  {
                    id: 'nominated_for_promotion',
                    name: 'Nominated for promotion',
                    count: table.stats?.nominated_for_promotion_count,
                  },
                  {
                    id: 'department_owner_non_calibrated',
                    name: 'HoD not calibrated',
                    count: table.stats?.department_owner_non_calibrated_count,
                  },
                  {
                    id: 'function_owner_non_calibrated',
                    name: 'HoF not calibrated',
                    count: table.stats?.function_owner_non_calibrated_count,
                  },
                  {
                    id: 'hod_hof_mismatch',
                    name: 'HoD-HoF mismatch',
                    count: table.stats?.hod_hof_mismatch_count,
                  },
                ]}
                filters={table.filterBy}
                disabled={table.loading}
                onChange={filters => {
                  table.onFilterChange(filters)
                }}
                label="Calibration flags"
              />
            </Bar>
          </Flex>
          <Flex style={{ position: 'relative' }} flex="1 0">
            <SelectTableWrapper
              enabled={allowBulkCalibration || enabledBulkDelegate}
              filters={table.filterBy}
              tableDataLength={table.data.length}
              onChange={handleSelect}
              onControlsLoaded={setSelectControls}
            >
              <AdjustableTable<TalentUIInterface, TalentPerformanceStatsInterface>
                name={TableNames.TalentPerformance}
                useWindowScroll
                dataType="Employee"
                hiddenCells={{
                  [functionalManagerColumn.idPoint]:
                    !performanceSettings?.enable_functional_management,
                  [hofRatingColumn.idPoint]:
                    !performanceSettings?.enable_functional_management,
                  [hofGradeColumn.idPoint]:
                    !performanceSettings?.enable_functional_management,
                  [fmGradeColumn.idPoint]:
                    !performanceSettings?.enable_functional_management,
                  [fmGradePlainColumn.idPoint]:
                    !performanceSettings?.enable_functional_management,
                  [systemRecommendedGradeColumn.idPoint]: showRanking,
                  [finalGradeColumn.idPoint]: showRanking,
                  [calibratedBarRaiserColumn.idPoint]: !showNewScorecards,
                  'department_grade_calibrator.id': !isDepartmentType(talentType),
                }}
                row={row}
                {...table}
                tableSettings={{
                  hidden: ['Department', 'Delivery (LM)', 'Skills (FM)', 'Bar raiser'],
                  visible: [],
                }}
                noDataMessage="No employees"
                orderingMode={orderingMode}
                disabledSorting={showRanking}
                renderSeparatorContent={renderSeparatorContent}
                selectedOrderingIds={selectedOrderingIds}
                onChangeOrder={onChangeOrder}
                onRowClick={talent => onRowClick(talent)}
                selectedCount={
                  selectionState?.isAllSelected
                    ? table.count - selectionState.excludeListIds.size
                    : selectedRows.length
                }
              />
            </SelectTableWrapper>
          </Flex>
        </Flex>
      </Widget>
      {openSidebar && !!selectedTalent ? (
        <SummarySidebar
          isOpen
          talentType={talentType}
          canChangeGrade={
            isDepartmentType(talentType) ? !!canChangeHoDGrades : !!canChangeHoFGrades
          }
          canDelegate={!!allowDelegation}
          selector={calibrationSelector}
          onClose={() => {
            setScrollToBarRaiser(false)
            setOpenSidebar(false)
            setSelectedTalent(undefined)
          }}
          talent={selectedTalent.talent}
          currentIndex={selectedTalent.index + 1}
          total={tableDataWithoutSeparators.length}
          onReviewRequested={
            cycleEditable
              ? () => {
                  setOpenReviewPopup(true)
                }
              : undefined
          }
          onGradeSelected={async option => {
            const grade = option?.id === FinalGrade.Empty ? FinalGrade.None : option?.id

            if (grade !== undefined && isDepartmentType(talentType)) {
              await changeHoDGrade(selectedTalent.talent.id, grade)
            } else if (grade !== undefined && isFunctionType(talentType)) {
              await changeHoFGrade(selectedTalent.talent.id, grade)
            }
          }}
          onPageChanaged={page => {
            setScrollToBarRaiser(false)
            const index = page - 1
            const needToLoadNextPage =
              tableDataWithoutSeparators.length < table.count &&
              tableDataWithoutSeparators.length - index <= 10 &&
              !table.nextPageLoading

            if (needToLoadNextPage) {
              table.fetchNextPage()
            }

            if (tableDataWithoutSeparators[index]) {
              setSelectedTalent({
                talent: tableDataWithoutSeparators[index],
                index,
              })
            } else {
              setQueuedSidebarTalent(index)
            }
          }}
          onDelegate={cycleEditable ? onDelegate : undefined}
          scrollToBarRaiser={scrollToBarRaiser}
        />
      ) : null}
      {openReviewPopup && selectedTalent && (
        <RequestFeedback
          type="popup"
          canRequest
          performanceCycle={selectedTalent.talent.cycle as RequestFeedbackSelector}
          isNewFlow
          category={ReviewCategory.Performance}
          onClose={() => setOpenReviewPopup(false)}
          id={selectedTalent.talent.employee?.id}
          onAddRequest={() => {
            feedbackRequestsContext.refetch()
          }}
          requests={(feedbackRequestsContext?.data as NewFlowRequestsResponse)?.results}
        />
      )}
    </VStack>
  )
}

const LapeTalentPerformanceContent = connect(TalentPerformanceContent)

const TalentPerformance = (props: TalentProps) => {
  const { cycles, loading } = useSelectedPerformanceCycle()
  const { query } = useQuery()
  const selectedCycleId = query.cycle__id

  if (loading) {
    return <Loader />
  }
  const initialCycle = cycles.find(cycle =>
    selectedCycleId
      ? String(cycle.cycle_id) === selectedCycleId
      : cycle.performance_reviews_selected_cycle,
  )

  if (!initialCycle) {
    return <TalentCycleSettingsBanner />
  }

  return (
    <LapeTalentPerformanceContent
      {...props}
      cycles={cycles}
      initialCycleId={initialCycle.cycle_id!}
    />
  )
}

export default TalentPerformance
